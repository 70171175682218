import React from "react"
import Form from "../forms/contactFormStandMobile"
import styled from "styled-components"
import { motion } from "framer-motion";

const popUpVariant = {
    isOpen: { opacity: 1, transition: { duration: 0.2 } },
    notOpen: { opacity: 0, transition: { duration: 0.2 } }
};

const ComponentPopUpMobile = ({ form, setIsOpen, isOpen, pageContextName, btnForm}) => {
    if (pageContextName)
        if (!form.title.includes(pageContextName.toUpperCase())) {
            var toJoin = '<br/> Sobre ' + pageContextName
            form.title = form.title + toJoin.toUpperCase()
        }
    return (
        <ComponentPopUpMobileStyled>
            {isOpen &&
                <motion.div variants={popUpVariant}
                    initial="notOpen"
                    animate="isOpen"
                    className="container-form">
                    <div className="p-sticky">
                        <div id="form">
                            <div className="container-close" onClick={() => setIsOpen(false)}>
                                <svg height="512px" viewBox="0 0 512 512" width="512px" xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                                </svg>
                            </div>
                            <Form data={form}  btnText={btnForm.btnText} btnActionFunction={btnForm.btnLink} btnClass={btnForm.btnClass} />
                        </div>
                    </div>
                </motion.div>
            }
        </ComponentPopUpMobileStyled >
    )
}

export default ComponentPopUpMobile

const ComponentPopUpMobileStyled = styled.div`
  position: absolute;
  z-index: 999;
  .container-close{
    width: 2vh;
    position: absolute;
    right: 1vh;
    top: 1.5vh;
    svg{
      width: 2vh;
      height: 2vh;
      path{
        fill: #f2f2f2;
      }
    }
  }
  .container-form{
    position: fixed;
    top: 2vh;
    height: 100%;
    width: 100%;
    align-items: center;
    align-content: center;
    align-self: center;
    display: grid;
    vertical-align: middle;
    transition: all 0.2s ease-in-out;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .p-sticky{
    position: sticky;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 85%;

    #form{
      background-color: rgba(0, 0, 0, 0.8);
      width: 100%;
      min-width: 100%;
      padding-top: 4vh;
      padding-bottom: 4vh;
      padding-right: 3vh;
      padding-left: 3vh;
      margin: auto;
      border-radius: 10px;
    }
  }

`