import React from "react"
import styled from "styled-components"

import { Link } from 'gatsby'
import { Buttons } from '../buttons'
import font from "../../fonts/fonts.module.scss"

const StandShop = ({ cars, btn }) => {
    return (
        <StyledStandShop>
            <div className={font.oL + " container-stand-shop"}>
                {cars.map((car, index) => (
                    <a to={'https://standvirtual.hcsperformance.pt/' + car.node.slug} className="card-stand" key={'car' + index}>
                        <div className="container-img">
                            <img src={car.node.image?.sourceUrl} alt={car.node.image?.title} />
                        </div>
                        <div className="container-info">
                            <div className={font.oM + " grid two-columns"}>

                                <h3 className="">{car.node.paMarcas.nodes.length > 0 ? car.node.paMarcas.nodes[0].name : '----'}</h3>
                                <h2 className="text-right">{car.node.price}</h2>
                            </div>
                            <hr className="hr-grey" />
                            <p className=" uppercase">{car.node.paModelos.nodes.length > 0 ? car.node.paModelos.nodes[0].name : '----'}</p>
                            <p className="grey mt-half uppercase">{car.node.paMesAnos.nodes.length > 0 ? car.node.paMesAnos.nodes[0].name : '----'}</p>
                            <div className="container-km-combustivel grey ">
                                <li><p>{car.node.paKms.nodes.length > 0 ? car.node.paKms.nodes[0].name + ' KM' : '----'} </p></li>
                                <li><p> - </p></li>
                                <li><p>{car.node.paCombustivels.nodes.length > 0 ? car.node.paCombustivels.nodes[0].name : '----'}</p></li>
                            </div>

                        </div>
                    </a>
                ))}
            </div>
            <div className={font.oEL + ' p-relative container-btn'} style={{ paddingTop: "2vh"}}>
                <Buttons text={btn.btnText} actionFunction={() => { 
                    typeof window !== `undefined` && window.open(btn.btnLink ,'_blank') }} btnClass={btn.btnClass} />
            </div>
        </StyledStandShop >
    )
}

export default StandShop


const StyledStandShop = styled.div`
margin-bottom: 5vw;
margin-top: 10vh;
.grid{
    display: grid;
}

.two-columns{
    grid-template-columns: 50% 50%;
}

.uppercase{
    text-transform: uppercase;
}

.container-btn{
    min-width: 12vw;
    float: left;
    margin: 5vh 0vh 7vh 8vh;
  }

.container-km-combustivel{
    width: 100%;
    margin: 0;
    font-weight: 500;
    padding-bottom: 0;
    text-transform: uppercase;
    margin-top: 0.5vw;
    
    li{
        display: inline-block;
        margin-bottom: 10px;
        padding-right: 10px;
    }
}

.grey{
    color: #8d8d8d;
}

.mt-half{
    
    margin-top: 0.5vw;
}

.text-right{
    text-align: right;
}
.text-center{
    text-align: center;
}

.hr-grey{
    margin: 1vw;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(192,192,192, 0.8), rgba(0, 0, 0, 0));
}


.container-stand-shop{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 3%;
    margin: 1%;

    .card-stand{
        width: 25vw;
        margin: auto;
        padding: 0px;
        border-radius: 5px;
        box-shadow: 10px 15px 45px #7e7e7e38;

       

        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            -webkit-mask-image: -webkit-gradient(linear,center bottom,center top,color-stop(0.00,rgba(0,0,0,0)),color-stop(0.35,rgb(255 255 255)));
        }

        .container-info{
            margin-left: 2vw;
            margin-right: 2vw;
            margin-bottom: 2vw;
            margin-top: 1vw;
        }
    }
    
}
`