import React from "react"
import styled from "styled-components"

import font from "../../fonts/fonts.module.scss"

import { Link } from 'gatsby'
import { Buttons } from "../buttons"

const StandShopMobile = ({ cars, btn }) => {
    const standLength = cars.length

    if (standLength < 6)
        return (
            <StyledStandShop>
                <div className={font.oL + " container-stand-shop grid one-column"}>
                    {cars.map((car, index) => (
                        <a to={'https://standvirtual.hcsperformance.pt/' + car.node.slug} className="card-stand" key={'car' + index}>
                            <div className="container-img">
                                <img src={car.node.image?.sourceUrl} alt={car.node.image?.title} />
                            </div>
                            <div className={font.oM + " container-info"}>
                                <div className={font.oM + " grid two-columns"}>
                                    <h4 className=" lh-2">{car.node.paMarcas.nodes.length > 0 ? car.node.paMarcas.nodes[0].name : '----'}</h4>
                                    <h4 className=" lh-2 text-right">{car.node.price}</h4>
                                </div>

                                <hr className="hr-grey" />
                                <p className=" uppercase">{car.node.paModelos.nodes.length > 0 ? car.node.paModelos.nodes[0].name : '----'}</p>
                                <p className="grey mt-half uppercase lh-1-half">{car.node.paMesAnos.nodes.length > 0 ? car.node.paMesAnos.nodes[0].name : '----'}</p>
                                <p className="grey mt-half uppercase lh-1-half">{car.node.paKms.nodes.length > 0 ? car.node.paKms.nodes[0].name + ' KM' : '----'} </p>
                                <p className="grey mt-half uppercase lh-1-half">{car.node.paCombustivels.nodes.length > 0 ? car.node.paCombustivels.nodes[0].name : '----'}</p>


                            </div>
                        </a>
                    ))}
                </div>
                <div className={font.oEL + ' p-relative container-btn'} style={{ paddingTop: "2vh" }}>
                    <Buttons text={btn.btnText} actionFunction={() => {
                        typeof window !== `undefined` && window.open(btn.btnLink, '_blank')
                    }} btnClass={btn.btnClass} />
                </div>
            </StyledStandShop >
        )

    else
        return (
            <StyledStandShop>
                <div className={font.oL + " container-stand-shop grid two-columns"}>
                    {cars.map((car, index) => (
                        <Link to={'/stand/' + car.node.slug} className="card-stand" key={'car' + index}>
                            <div className="container-img">
                                <img src={car.node.image?.sourceUrl} alt={car.node.image?.title} />
                            </div>
                            <div className={font.oM + " container-info"}>
                                <h4 className=" lh-2">{car.node.paMarcas.nodes.length > 0 ? car.node.paMarcas.nodes[0].name : '----'}</h4>
                                <h4 className=" lh-2">{car.node.price}</h4>
                                <hr className="hr-grey" />
                                <p className=" uppercase">{car.node.paModelos.nodes.length > 0 ? car.node.paModelos.nodes[0].name : '----'}</p>
                                <p className="grey mt-half uppercase lh-1-half">{car.node.paMesAnos.nodes.length > 0 ? car.node.paMesAnos.nodes[0].name : '----'}</p>
                                <p className="grey mt-half uppercase lh-1-half">{car.node.paKms.nodes.length > 0 ? car.node.paKms.nodes[0].name + ' KM' : '----'} </p>
                                <p className="grey mt-half uppercase lh-1-half">{car.node.paCombustivels.nodes.length > 0 ? car.node.paCombustivels.nodes[0].name : '----'}</p>


                            </div>
                        </Link>
                    ))}
                </div>
                <div className={font.oEL + ' p-relative container-btn'} style={{ paddingTop: "2vh" }}>
                    <Buttons text={btn.btnText} actionFunction={() => {
                        typeof window !== `undefined` && window.open(btn.btnLink, '_blank')
                    }} btnClass={btn.btnClass} />
                </div>
            </StyledStandShop >
        )
}

export default StandShopMobile


const StyledStandShop = styled.div`
margin-top: 10vh;
.lh-2{
    line-height: 2;
}
.lh-1-half{
    line-height: 1.5;
}

.grid{
    display: grid;
}

.one-column{
    grid-template-columns: 100%;
}

.two-columns{
    grid-template-columns: 50% 50%;
}

.uppercase{
    text-transform: uppercase;
}
.container-km-combustivel{
    width: 100%;
    margin: 0;
    font-weight: 500;
    padding-bottom: 0;
    text-transform: uppercase;
    margin-top: 0.5vw;
    
    li{
        display: inline-block;
        margin-bottom: 10px;
        padding-right: 10px;
    }
}

.grey{
    color: #8d8d8d;
}

.mt-half{
    
    margin-top: 0.5vw;
}

.text-right{
    text-align: right;
}
.text-center{
    text-align: center;
}

.hr-grey{
    margin: 1vh;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(192,192,192, 0.75), rgba(0, 0, 0, 0));
}


.container-stand-shop{
    margin: 1%;

    .card-stand{
        width: 85%;
        margin: auto;
        padding: 0px;
        border-radius: 5px;
        box-shadow: 10px 15px 45px #7e7e7e38;
        margin-bottom: 5vh;

       

        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            -webkit-mask-image: -webkit-gradient(linear,center bottom,center top,color-stop(0.00,rgba(0,0,0,0)),color-stop(0.04,rgb(255 255 255)));
        }

        .container-info{
            margin-left: 2vw;
            margin-right: 2vw;
            margin-bottom: 2vw;
            margin-top: 1vw;
        }
    }
    
}

.container-btn{
    text-align: left;
    width: 35vw;
    margin: 1vh auto 5vh 6vh;
  }
`